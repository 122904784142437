/* global styles */

html,
body,
#root,
.MuiContainer-root {
	height: 100%;
}

.animate {
	animation: fadeInAnimation ease 3s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

/* nav component */

.navContainer > .link {
	text-decoration: none;
	color: #FFFFFF;
	font-size: 1rem;
	height: 64px;
	line-height: 64px;
	position: relative;
	text-align: left;
	font-family: 'PT Sans Narrow', sans-serif;
	padding: 0 24px;
	display: block;
	border-bottom: 2px solid #181717;
	text-transform: uppercase;
	font-weight: 700;
	letter-spacing: 1px;
}

.navContainer > .link.active {
	background: #121212;
	box-shadow: 4px 0px 0px 0px #121212;
}

.navContainer:nth-child(1) {
	border-top: 2px solid #181717;
}

.navContainer > .link:hover {
	cursor: pointer;
	background: #FFF;
	color: #000;
}

.MuiToolbar-root {
	height: 100%;
	width: 113px;
	z-index: 1;
	position: fixed !important;
	background: #000000;
	padding: 0px !important;
	box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}

.MuiDrawer-paper {
	top: 56px !important;
	width: 100%;
	height: fit-content !important;
	text-align: center;
	background-image: none !important;
	text-transform: uppercase;
}

.MuiDrawer-paper .link {
	color: #FFFFFF;
	text-decoration: none;
	font-family: 'PT Sans Narrow', sans-serif;
	font-weight: 700;
	letter-spacing: 1px;
	padding: 0 50px;
}

.MuiDrawer-paper ul li {
	text-align: center;
}

@media only screen and (min-width: 1200px) {

	.MuiGrid-root {
		margin-top: 0 !important;
		flex-direction: unset !important;
	}

	.MuiGrid-container {
		justify-content: center;
	}

	.MuiContainer-root {
		flex-direction: column;
		margin-top: 64px;
	}

}

@media only screen and (max-width: 1199px) {

	.MuiContainer-root {
		align-items: unset !important;
	}

	.MuiContainer-root > .MuiGrid-root:nth-child(1) {
		margin-top: 32px !important;
	}

	.MuiGrid-root {
		flex-direction: unset !important;
		align-items: center;
	}

}

@media only screen and (max-width : 899px) {

	.MuiContainer-root {
		height: auto;
	}

	.MuiContainer-root .animation {
		margin-top: 90px;
	}

	header > div.MuiToolbar-root {
		position: fixed;
		height: 64px;
		width: 100%;
		box-shadow: none;
	}

	header > div.MuiToolbar-root > button {
		margin-left: 10px;
	}

	#home {
		height: 100%;
	}

	header {
		height: 64px;
	}
}

/* home component*/

#home {
	background: #000064;
	background-image: url(../public/images/bg.gif);
	background-size: cover;
	background-blend-mode: multiply;
	background-position: top;
	max-width: 100%;
	margin-top: 0;
	display: flex;
	justify-content: center;
	flex-direction: column;
	text-transform: uppercase;
}

#home,
#home h1,
#home p,
#home a {
	font-family: 'PT Sans Narrow', serif;
	text-align: center;
}

#home p {
	margin-bottom: 16px;
}

#home .linkContainer {
	flex-direction: row;
	justify-content: center;
	margin-bottom: 24px;
	width: 100%;
	align-self: center;
}

#home a.link {
	color: #FFFFFF;
	text-decoration: none;
	min-width: 64px;
	width: 33%;
	text-align: center;
	position: relative;
	font-weight: 700;
	letter-spacing: 1px;
	margin-left: 0px;
}

#home a.link:nth-child(1) {
	border-right: 2px solid #FFFFFF;
}

#home a.link:hover {
	color: #b1ff06;
	cursor: pointer;
}

#home .socialIcons {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

#home .socialIcons a:nth-child(2) {
	margin-left: 16px;
}

#home .socialIcons .MuiAvatar-root {
	width: 30px;
	height: 30px;
}

#home .socialIcons .MuiAvatar-root:hover {
	opacity: 0.7;
}

#home .socialIcons .MuiTypography-root:nth-child(2) .MuiAvatar-root:hover {
	background-color: #0077B5;
}

@media only screen and (max-width: 600px) {
	#home h1 {
		font-size: 17vw;
	}

	#home p {
		font-size: 3.6vw;
	}
}

/* projects component */

#projects {
	display: flex;
	align-items: center;
}

#projects .MuiGrid-item {
	padding-top: 0 !important;
	padding-bottom: 32px;
}

#projects .MuiCard-root {
	height: 100%;
	display: flex;
	flex-direction: column;
	text-align: center;
	background: unset;
	box-shadow: unset;
}

#projects .MuiCardContent-root p {
	width: 80%;
	margin: 0 auto;
	padding-bottom: 8px;
}

#projects .MuiCardContent-root {
	flex-grow: 1;
	padding-bottom: 8px;
}

#projects .MuiCardActions-root {
	justify-content: center;
	padding-bottom: 16px;
}

#projects .MuiCardActions-root .MuiAvatar-root {
	height: 25px;
	width: 25px;
}

#projects .MuiCardActions-root .MuiAvatar-root:hover {
	opacity: 0.5;
}


#projects .MuiCardActions-root .MuiAvatar-root .MuiSvgIcon-root {
	height: 20px;
	width: 20px;
}


@media only screen and (min-width: 900px) {
	#projects, #resume {
		padding-left: 137px;
	}
}

/* resume component */

#resume {
	display: flex;
	justify-content: center;
	flex-direction: column;
	margin-top: 0;
}

.MuiAccordion-root .MuiTypography-root {
    letter-spacing: 0.5px;
}

.MuiContainer-root > .MuiAccordion-root {
    margin-top: 16px !important;
}


.MuiAccordionSummary-content {
   justify-content: space-between;
   align-items: center;
 }

 .MuiAccordionSummary-content.Mui-expanded {
    margin: 0 !important;
 }

 .MuiAccordionSummary-content .MuiSvgIcon-root {
    transform: rotate(0deg);
    transition: transform 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
 }

 .Mui-expanded .MuiAccordionSummary-content .MuiSvgIcon-root {
    transform: rotate(90deg);
    transition: transform 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 48px !important;
    
  }

  @media only screen and (max-width: 500px) {

    .MuiAccordion-root .MuiTypography-root {
        font-size: 2.8vw;
    }

    .MuiAccordionSummary-content .MuiSvgIcon-root {
        /* margin-right: 5px; */
        font-size: 2.8vw;
    }
    
  }

  @media only screen and (max-width: 899px) {
    .MuiContainer-root .MuiAvatar-root {
        margin-top: 32px;
    }

    .MuiAccordion-root:last-of-type {
        margin-bottom: 32px !important;
    }

  }